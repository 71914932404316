import Discover from '../components/Home/Discover'
import Funds from '../components/Home/Funds'
import HowItWorks from '../components/Home/HowItWorks'
import News from '../components/Home/News'
import Quiz from '../components/Home/Quiz'
import Stories from '../components/Home/Stories'
import Front from '../components/Home/Front'

export default function Home({ user }) {
  const isSpecialUser = user !== 'user'

  return (
    <main>
      <Front user={user} />
      {isSpecialUser && <Funds />}
      <Discover user={user} />
      {isSpecialUser ? (
        <>
          <Quiz />
          <HowItWorks user={user} />
        </>
      ) : (
        <>
          <Quiz />
          <HowItWorks user={user} />
        </>
      )}
      {isSpecialUser && (
        <>
           <News />
          <Stories />
       
        </>
      )}
    </main>
  )
}
