import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { cards } from "../components/Home/data";
import LoadingPage from "./LoadingPage";

const Games = () => {
  const { name } = useParams();
  const [game, setGame] = useState(null);

  useEffect(() => {
    const gameData = cards?.find((game) => game.path === name);
    setGame(gameData);
  }, [name]);

  if (!game) {
    return <LoadingPage />;
  }
  return (
    <div style={styles.container}>
      <iframe
        src={game.iframeUrl}
        style={styles.webview}
        title={game.title}
        allowFullScreen
        referrerPolicy="no-referrer"
      />
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    marginTop: "20px",
    backgroundColor: "black",
  },
  title: {
    fontSize: "24px",
    textAlign: "center",
    margin: "10px",
    color: "white",
  },
  webview: {
    width: "100%",
    height: "100%",
    border: "none",
    margin: 0,
    padding: 0,
    overflow: "hidden",
    zIndex: 999999,
  },
};

export default Games;
