import React from 'react'
import Container from '../Container'
import image from '../../assets/home/zar.webp'

const NewsCard = ({ text, name }) => (
  <div className='w-[320px] px-4 py-6 bg-white rounded-md news_card_shadow'>
    <p className='text-[#5B6469] font-bold text-[15px]'>{text} </p>
    <div className='pt-7 text-[13px] flex items-center gap-2'>
      <div>
        <h1 className='font-medium'>{name}</h1>
      </div>
    </div>
  </div>
)

export default function Stories() {
  return (
    <section className='my-14'>
      <Container>
        <div className='pt-14 pb-4  overflow-auto  flex items-center lg:flex-nowrap flex-wrap gap-1'>
          <article className='lg:w-1/2 w-full lg:pb-0  flex flex-col lg:items-start items-center lg:ml-14 lg:mt-0'>
            <h2 className='text-amber-600 text-center text-4xl my-4 m-auto' >Cad a Dúirt ár gCamróirí</h2>
           <img src={image} alt="imageCasino" className='sm:h-[450px] h-[200px] w-full object-cover' />
          </article>
          <div className='flex gap-4 sm:flex-nowrap flex-wrap lg:w-1/2 mx-auto'>
            <div className='rounded-md w-full flex flex-col gap-3 items-center'>
              <NewsCard
                text='Ich bin seit Monaten ein treuer Freeluckygame.com-Spieler und kann vom kostenlosen  Casino Stud Poker nicht genug bekommen. Testen Sie meine Pokerfähigkeiten und genießen Sie einen entspannten Abend'
                name='Samantha R.'
              />
              <NewsCard
              text='Ich bin seit Monaten ein treuer Spieler bei freeluckygame.com und kann vom kostenlosen Casino Stud Poker nicht genug bekommen. Ein großartiges Spiel, um Ihre geistigen Fähigkeiten zu testen. Es steckt voller herausfordernder Rätsel und logischer Fragen. Der Schwierigkeitsgrad steigt mit fortschreitendem Spielverlauf, sodass das Spiel stets interessant bleibt.'
                name='David K.'
              />
              <NewsCard
                text='Mystery Joker 6000 ist wie eine versteckte Schatztruhe voller Spaß bei  freeluckygame.com . So etwas habe ich noch nie erlebt. Die Überraschungen und Boni lassen mich immer wieder zurückkommen und ich bin immer gespannt, welche kosmischen Geheimnisse das Spiel bereithält!'
                name='Linda M.'
              />
            </div>
            <div className='rounded-md w-full flex flex-col gap-3 items-center'>
              <NewsCard
                text=' freeluckygame.com  ist mein Tor zum Gaming. Ihre benutzerfreundliche Plattform, die große Auswahl an Spielen und der hervorragende Kundensupport zeichnen sie aus. Ich kann das kostenlose Social-Casino Stud Poker, Double Exposure Blackjack und mehr an einem Ort genießen!'
                name='Samuel P.'
              />
              <NewsCard
                text='Ich kann gar nicht ausdrücken, wie sehr ich  freeluckygame.com  liebe. Sein Double Exposure Blackjack hat für mich das Spiel verändert. Es verleiht dem traditionellen Blackjack ein neues Maß an Spannung. Das ganze Erlebnis fühlt sich futuristisch und fair an, was genau das ist, was ich in einem kostenlosen Social-Casino suche.'
                name='Ella S.'
              />
              <NewsCard
                text='Ich bin seit Jahren ein begeisterter Gamer und  freeluckygame.com  hat sich schnell zu meiner Lieblings-Gaming-Plattform entwickelt. Ein tolles Abenteuerspiel! Die Grafik ist ausgezeichnet und die Steuerung im Spiel ist sehr benutzerfreundlich. Die Spielwelt ist riesig und bietet jede Menge Inhalte zum Erkunden.!'
                name='Brian C.'
              />
            </div>
          </div>
        </div>
      </Container>
    </section>
  )
}
