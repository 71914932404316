import React from "react";

function Terms() {
  return (
    <div className="max-w-4xl mx-auto p-5 text-white">
    <h1 className="text-4xl font-bold mb-6">
      Allgemeine Geschäftsbedingungen
    </h1>
    <p className="mb-4">
      Willkommen bei freeluckygame.com! Bitte lesen Sie diese Allgemeinen Geschäftsbedingungen sorgfältig durch, bevor Sie unsere von Freeluckygame betriebene Website nutzen. Durch den Zugriff auf oder die Nutzung unseres Dienstes erklären Sie sich mit diesen Bedingungen einverstanden. Wenn Sie mit einem Teil der Bedingungen nicht einverstanden sind, dürfen Sie den Dienst nicht nutzen.
    </p>
    <h2 className="text-2xl font-semibold mt-4 mb-2">
      Berechtigung:
    </h2>
    <p className="mb-4">
      Sie müssen mindestens 18 Jahre alt sein, um unsere Website nutzen zu können. Mit Ihrer Zustimmung zu diesen Bedingungen versichern und garantieren Sie, dass Sie mindestens 18 Jahre alt sind.
    </p>


   
    <h2 className="text-2xl font-semibold mt-4 mb-2">
      Änderungen:
    </h2>
    <p className="mb-4">
      Wir behalten uns das Recht vor, diese Bedingungen jederzeit zu ändern oder zu ersetzen. Wir werden versuchen, mindestens 30 Tage vor Inkrafttreten neuer Bedingungen eine Ankündigung zu machen.
    </p>
    <h2 className="text-2xl font-semibold  mb-2">
      Spiele:
      </h2>
      <p className="text-base ">
      Die auf unserer Website angebotenen Spiele sind vollständig kostenlos und ausschließlich zu Unterhaltungszwecken gedacht. Diese Spiele dürfen nicht für Glücksspiele oder Wetten verwendet werden. Unsere Spiele sind so gestaltet, dass sie unseren Nutzern ein angenehmes Erlebnis bieten.
      </p>
    <h2 className="text-2xl font-semibold mt-4 mb-2">
      Kontaktieren Sie uns:
    </h2>
    <p className="mb-12">
      Wenn Sie Fragen zu diesen Bedingungen haben, kontaktieren Sie uns bitte unter support@freeluckygame.com.
    </p>
    <h1 className="text-4xl font-bold mb-6">Cookie-Richtlinie</h1>
      <h2 className="text-2xl font-semibold mt-4 mb-2">Was sind Cookies?</h2>
      <p className="text-base mb-4">
        Cookies sind kleine Textdateien, die von Ihrem Webbrowser gesendet werden, wenn Sie eine Website besuchen. Ein Cookie wird in Ihrem Webbrowser gespeichert und ermöglicht es dem Dienst oder Drittanbietern, Sie zu erkennen und Ihren nächsten Besuch einfacher zu gestalten sowie den Dienst nützlicher für Sie zu machen.
      </p>

      <h2 className="text-2xl font-semibold mt-4 mb-2">Wie Freeluckygame Cookies verwendet:</h2>
      <p className="text-base mb-4">
        Wenn Sie den Dienst nutzen und darauf zugreifen, können wir eine Reihe von Cookies in Ihrem Webbrowser platzieren. Wir verwenden Cookies zu folgenden Zwecken: um bestimmte Funktionen des Dienstes zu ermöglichen, um Analysen bereitzustellen, um Ihre Präferenzen zu speichern, um die Zustellung von Werbung zu ermöglichen, einschließlich verhaltensbezogener Werbung.
      </p>

      <h2 className="text-2xl font-semibold mt-4 mb-2">Cookies von Drittanbietern:</h2>
      <p className="text-base mb-4">
        Zusätzlich zu unseren eigenen Cookies verwenden wir möglicherweise auch verschiedene Cookies von Drittanbietern, um Nutzung
statistics of the service, deliver advertisements on and through the service, and so on.
      </p>

      <h2 className="text-2xl font-semibold mt-4 mb-2">Ihre Wahlmöglichkeiten bezüglich Cookies:</h2>
      <p className="text-base mb-4">
        Wenn Sie Cookies löschen oder Ihren Webbrowser anweisen möchten, Cookies zu löschen oder abzulehnen, besuchen Sie bitte die Hilfeseiten Ihres Webbrowsers. Bitte beachten Sie jedoch, dass Sie möglicherweise nicht alle Funktionen unseres Angebots nutzen können, wenn Sie Cookies löschen oder ablehnen, Ihre Präferenzen möglicherweise nicht speichern können und einige unserer Seiten möglicherweise nicht richtig angezeigt werden.
      </p>

      <h2 className="text-2xl font-semibold mt-4 mb-2">Weitere Informationen:</h2>
      <p className="text-base mb-4">
        Wenn Sie Fragen zur Verwendung von Cookies haben, kontaktieren Sie uns bitte unter support@freeluckygame.com.
      </p>
    </div>

  );
}

export default Terms;
