import Container from "../Container";


export default function Funds() {
  return (
    <section className="my-14">
      <Container>
       
      </Container>
    </section>
  );
}
