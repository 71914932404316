import { useEffect, useState } from "react";
import MyRoutes from "./MyRoutes";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import axios from "axios";
import LoadingPage from "./Pages/LoadingPage";
import CookieConsent from "react-cookie-consent";

// function loadGTM() {
//   const script = document.createElement("script");
//   script.src = "https://www.googletagmanager.com/gtm.js?id=GTM-WT55PC3W";
//   script.async = true;
//   document.head.appendChild(script);
// }

 function App() {
//   useEffect(() => {
//     loadGTM();
//   }, []);

  const [user, setUser] = useState("");
  const [loading, setLoading] = useState(false);

  // function fetchAndSendIP() {
  //  setLoading(true)
  //   axios
  //     .post('http://localhost:3030/api/setLanguage')
  //     .then((res) => {
  //       const { message } = res.data

  //       setUser(message)
  //       setLoading(false)
  //     })
  //     .catch((error) => console.error('Error with backend:', error))
  // }

  // useEffect(() => {
  //   fetchAndSendIP()
  // }, [])

  //console.log("app", user);

  return (
    <div className="bg-[#2C3639]">
      {loading ? (
        <LoadingPage />
      ) : (
        <>
          <Navbar user={user} />
          <CookieConsent
  location="bottom"
  buttonText="Akzeptieren"
  cookieName="mySiteCookieConsent"
  style={{ 
    background: "gray", 
    position: "fixed", // Sabit pozisyon ekleyerek sayfa ortasında görünmesini sağlıyoruz.
    left: "80%", // Sol tarafa %50 oranında yerleştir.
    top: "92%", // Üst tarafa %50 oranında yerleştir.
    transform: "translate(-50%, -50%)", // Gerçek merkezi bulmak için yarısını sola ve yukarıya çek.
    width: "80%", // Genişlik
    height:'100px',
    maxWidth: "600px", // Maksimum genişlik
    padding: "10px", // İç boşluk
    boxSizing: "border-box", // Kutu boyutlandırmasını ayarla
    zIndex: 1000 // Diğer elementlerin üzerinde görünmesini sağla
  }}
  buttonStyle={{ 
    color: "#4e503b", 
    fontSize: "13px",
    padding: "10px 20px", // Düğme boyutunu ayarla
    cursor: "pointer" // Fare imlecini düğme üzerindeyken işaretçiye dönüştür
  }}
  expires={150}
  onAccept={() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "consent_given",
    });
  }}
>
Diese Website verwendet Cookies. Bitte akzeptieren Sie Cookies für ein besseres Nutzererlebnis.

</CookieConsent>

          <MyRoutes user={user} />
          <Footer />
        </>
      )}
    </div>
  );
}

export default App;
