import {
 discover_3,
 discover_4,
 discover_9,
 discover_10,
 discover_5,
 discover_6,
 discover_7,
 discover_8,
 discover_1,
 discover_2,
 logo_1,
 logo39,
 logo_38,
 logo_2,
 logo_5,
 logo_4,
 logo_3,
 logo_6,
 logo_7,
 logo_8,
 logo_9,
 logo_10,
 logo_11,
 logo_12,
 logo_13,
 logo_14,
 logo_15,
} from '../../assets/home'
const cards = [
 {
   id: 1,
   image: discover_1,
   title: 'Rotiki',
   score: '9.3',
   to: '/Games',
   view:6993,
   path:"Rotiki",
   iframeUrl:
     'https://asccw.playngonetwork.com/casino/ContainerLauncher?pid=2&gid=rotiki&lang=en_GB&practice=1&channel=desktop&demo=2',
   rel: 'noreferrer',
   about: 'Ziel des Spiels ist es, fünf oder mehr Symbole zu kombinieren, um eine Gewinngruppe zu bilden..',
 },
 {
   id: 2,
   image: discover_2,
   title: 'Captan Glum',
   score: '9.8',
   to: '/Games',
   path:"Captan_glum",
   view:8198,
   rel: 'noreferrer',
   iframeUrl:
     'https://asccw.playngonetwork.com/casino/ContainerLauncher?pid=2&gid=piratehunter&lang=en_GB&practice=1&channel=desktop&demo=2',
   about:
     'Zu seiner Hilfe verfügt er über ein permanentes Wild-Symbol, das bei allen Drehungen im Basisspiel auf den Walzen 2 schwimmt..',
 },
 {
   id: 3,
   image: discover_3,
   title: 'Golden Siris',
   to: '/Games',
   view:7377,
   path:"Golden_siris",
   score: '9.1',
   rel: 'noreferrer',
   iframeUrl:
     'https://asccw.playngonetwork.com/casino/ContainerLauncher?pid=2&gid=merlinsgrimoire&lang=en_GB&practice=1&channel=desktop&demo=2',
   about:
     'Der Goldene Osiris birgt die Geheimnisse des Glücks..',
 },
 {
   id: 4,
   image: discover_4,
   title: 'Nova7s',
   score: '9.4',
   to: '/Games',
   path:"Nova7s",
   view:5043,
   rel: 'noreferrer',
   iframeUrl:
     'https://asccw.playngonetwork.com/casino/ContainerLauncher?pid=2&gid=journeyofflame&lang=en_GB&practice=1&channel=desktop&demo=2',
   about:
     "Der Spielautomat Merlin's Magic Respins ist eine Fortsetzung des beliebten Spielautomaten Merlin's Millions.",
 },
 {
   id: 5,
   image: discover_5,
   title: "Fire Joker",
   score: '9.9',
   path:"Fire_joker",
   to: '/Games',
   view:9344,
   rel: 'noreferrer',
   iframeUrl:
     'https://asccw.playngonetwork.com/casino/ContainerLauncher?pid=2&gid=ankhofanubis&lang=en_GB&practice=1&channel=desktop&demo=2',
   about:
     'Der teuflische Feuer-Joker dient als Wild-Symbol, das an jeder Position auf allen drei Walzen erscheinen kann..',
 },
 {
   id: 6,
   image: discover_6,
   title: 'Sticky Joker',
   to: '/Games',
   score: '9',
   view:7494,
   rel: 'noreferrer',
   path:"Sticky_joker",
   iframeUrl:
     'https://asccw.playngonetwork.com/casino/ContainerLauncher?pid=2&gid=firejoker&lang=en_GB&practice=1&channel=desktop&demo=2',
   about:
     'Sticky Joker ist ein klassischer 3-Walzen-Slot mit großen Gewinnmöglichkeiten.',
 },
 {
   id: 7,
   image: discover_7,
   title: 'Captain Xends',
   score: '9.4',
   to: '/Games',
   view:6009,
   rel: 'noreferrer',
   path:"Captain_xends",
   iframeUrl:
     'https://asccw.playngonetwork.com/casino/ContainerLauncher?pid=2&gid=christmasjoker&lang=en_GB&practice=1&channel=desktop&demo=2',
   about:
     'In diesem Spiel schafft die Funktion bis zu 32.768 Gewinnmöglichkeiten',
 },
 {
   id: 8,
   image: discover_8,
   title: 'Odin Story',
   score: '9.7',
   to: '/Games',
   view:8302,
   rel: 'noreferrer',
   path:"Odin_story",
   iframeUrl:
     'https://asccw.playngonetwork.com/casino/ContainerLauncher?pid=2&gid=captxenosadventure&lang=en_GB&practice=1&channel=desktop&demo=2',
   about:
     'Story of Odin ist ein 5x4-Slot im nordischen Stil des Entwicklers Spinomenal, bei dem Sie bis zu 100 Freispiele mit garantiert gestapelten Wilds gewinnen können.',
 },

 {
   id: 9,
   score: '8.9',
   image: discover_9,
   title: 'Viking',
   view:3238,
   path:"Viking",
   about:
     'Einfach ausgedrückt ist der Viking Runecraft-Slot für Hochrisikospieler gedacht.',
   to: '/Games',
   rel: 'noreferrer',
   iframeUrl:
     'https://asccw.playngonetwork.com/casino/ContainerLauncher?pid=2&gid=odinprotectorofrealms&lang=en_GB&practice=1&channel=desktop&demo=2',
 },
 {
   id: 10,
   image: discover_10,
   title: 'Merlins grimoire',
   view:8372,
   path:"Merlins_grimoire",
   score: '9.9',
   to: '/Games',
   rel: 'noreferrer',
   iframeUrl:
     'https://asccw.playngonetwork.com/casino/ContainerLauncher?pid=2&gid=vikingrunecraft100&lang=en_GB&practice=1&channel=desktop&demo=2',
   about:
     "Merlin's Grimoire ermöglicht es Spielern, mehrere Zaubersprüche zu wirken, darunter Freispiele, expandierende Symbole und teilende Symbole.",
 },
]

const links = [
 {
   id: 16,
   image: logo_15,
   title: '',
   to: 'https://promotion.32red.com/250-super-spins-and-10-ultra-spins-bonus/?kpid=affiliate_casino_uk_netrefer_32red_250ss10us_desktop&promo=250ss10us&btag=320657596_ECF29576D55A457FBD801995E7856D7D&sref=source&source=IeTXQv2JT7&affiliateId=1&pid=95622237&bid=37547',
   rel: 'noreferrer',
 },
 {
   id: 13,
   image: logo_12,
   title: '',
   to: 'https://promotion.32red.com/250-super-spins-and-10-ultra-spins-bonus/?kpid=affiliate_casino_uk_netrefer_32red_250ss10us_desktop&promo=250ss10us&btag=320657596_ECF29576D55A457FBD801995E7856D7D&sref=source&source=IeTXQv2JT7&affiliateId=1&pid=95622237&bid=37547',
   rel: 'noreferrer',
 },
 {
   id: 15,
   image: logo_14,
   title: '',
   to: 'https://www.top10bestonlinecasinos.co.uk/v?product_id=894&url=https%3A%2F%2Fbfpartners.click%2Fo%2F7sR0GB%3Fsite_id%3D110203%26s1%3D%5Btracking-subid%5D&cms_platform=xsite&rank=4&bi=%7B%22blrs%22%3A82382%2C%22utm_source%22%3A%22Google%22%7D&comp_iid=a0d5dea2-f48b-4026-86e7-7f6a919fbf5d&uid=fAG1nPYA19VEXg623zbg&riid=YltWsBPlDlYS899348dv&tkn=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiY21zIiwidmVyc2lvbiI6MSwiaGFzaCI6InVvSGgySTJTdEVYOHd0dlpBSWN3Y214dmVwcz0iLCJpYXQiOjE3MDc3MTM3ODUsImV4cCI6MTcwNzgwMDE4NX0.KAUj8U3KVIZOM8Hij8HscDqL0eZ6S9xtN0-OAXbzD_4&gid=45572406.1706266049',
   rel: 'noreferrer',
 },
 {
   id: 1,
   image: logo_1,
   title: '',
   to: 'https://www.top10bestonlinecasinos.co.uk/v?product_id=13915&url=https%3A%2F%2Fquinnbet.click%2Fo%2FtmcrtK%3Flpage%3DV0djng%26s1%3D%5Btracking-subid%5D%26ni_abd%3D600&cms_platform=xsite&rank=5&bi=%7B%22blrs%22%3A79439%2C%22utm_source%22%3A%22Google%22%7D&comp_iid=a0d5dea2-f48b-4026-86e7-7f6a919fbf5d&uid=fAG1nPYA19VEXg623zbg&riid=YltWsBPlDlYS899348dv&tkn=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiY21zIiwidmVyc2lvbiI6MSwiaGFzaCI6IjJMVkgrY3k2MTE2ejRpcGR2MXpCQnVTNUprRT0iLCJpYXQiOjE3MDc3MTM3ODUsImV4cCI6MTcwNzgwMDE4NX0.-0ia535HPulOQbqJttEBgUj9GUyl8GTOpEzr4F1wM7M&gid=45572406.1706266049',
   rel: 'noreferrer',

   score: 9.2,
 },

 {
   id: 2,
   image: logo_2,
   title: '',
   to: 'https://www.top10bestonlinecasinos.co.uk/v?product_id=10911&url=https%3A%2F%2Fwlvirginbetuk.adsrv.eacdn.com%2FC.ashx%3Fbtag%3Da_44787b_131c_%26affid%3D33118%26siteid%3D44787%26adid%3D131%26c%3D%5Btracking-subid%5D%26ni_abd%3D600&cms_platform=xsite&rank=6&bi=%7B%22blrs%22%3A78989%2C%22utm_source%22%3A%22Google%22%7D&comp_iid=a0d5dea2-f48b-4026-86e7-7f6a919fbf5d&uid=fAG1nPYA19VEXg623zbg&riid=YltWsBPlDlYS899348dv&tkn=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiY21zIiwidmVyc2lvbiI6MSwiaGFzaCI6Ik44SWJZN294SGVsS3dpZDlvZlVHNHRSblo2OD0iLCJpYXQiOjE3MDc3MTM3ODUsImV4cCI6MTcwNzgwMDE4NX0.tcz9gI5ooH5TCBDKbcMDT0Bg6UFKvn2jV0CH_m2rOCU&gid=45572406.1706266049',
   rel: 'noreferrer',
 },
 {
   id: 14,
   image: logo_13,
   title: '',
   to: 'https://www.top10bestonlinecasinos.co.uk/v?product_id=10590&url=https%3A%2F%2Fmrq.rocks%2Fo%2FgfUvFY%3Flpage%3DwBwH2g%26s3%3D%5Btracking-subid%5D%26ni_abd%3D600&cms_platform=xsite&rank=3&bi=%7B%22blrs%22%3A81170%2C%22utm_source%22%3A%22Google%22%7D&comp_iid=a0d5dea2-f48b-4026-86e7-7f6a919fbf5d&prepop=s1%3Dcasino-desktop%26s2%3D3&uid=fAG1nPYA19VEXg623zbg&riid=YltWsBPlDlYS899348dv&tkn=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiY21zIiwidmVyc2lvbiI6MSwiaGFzaCI6IlhtSWJIajM1QUhTZmt5TVRHYVFPeXZoTnpWRT0iLCJpYXQiOjE3MDc3MTM3ODUsImV4cCI6MTcwNzgwMDE4NX0.qgCoDmPp5l-Ggrv7bizexxgXtbQ7_ru8NZ5iWhq4zsE&gid=45572406.1706266049',
   rel: 'noreferrer',
 },
 {
   id: 39,
   image: logo39,
   title: '',
   to: 'https://www.livescorebet.com/uk/promo/p/2023-CAS-SLOT-LP-BB/index.html?utm_medium=Affiliates&utm_source=171&utm_campaign=853',
   rel: 'noreferrer',
 },
 {
   id: 5,
   image: logo_5,
   title: '',
   to: 'https://www.top10bestonlinecasinos.co.uk/v?product_id=13851&url=https%3A%2F%2Frecord.lottolandaffiliates.com%2F_iPj7tkCGqfus3YX4rqCiFmNd7ZgqdRLk%2F1%2F%3Fpayload%3D%5Btracking-subid%5D&cms_platform=xsite&rank=9&bi=%7B%22blrs%22%3A82419%2C%22utm_source%22%3A%22Google%22%7D&comp_iid=a0d5dea2-f48b-4026-86e7-7f6a919fbf5d&uid=fAG1nPYA19VEXg623zbg&riid=YltWsBPlDlYS899348dv&tkn=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiY21zIiwidmVyc2lvbiI6MSwiaGFzaCI6Ilk5OG4xN3JNeURFYlY0Yk1DWU9tMVJYRVlUZz0iLCJpYXQiOjE3MDc3MTM3ODUsImV4cCI6MTcwNzgwMDE4NX0.oboNpw40gJY4aSUBhR2ZajtiusFN3-jTbmLx0UBhObk&gid=45572406.1706266049',
   rel: 'noreferrer',
 },

 {
   id: 11,
   image: logo_8,
   title: '',
   to: 'https://casino.bet365.com/home/en?affiliate=365_02863180',
   rel: 'noreferrer',
 },

 {
   id: 38,
   image: logo_38,
   title: '',
   to: 'https://promo.coral.co.uk/en/promo/lp/casino-slots/rick-and-morty-megaways-bet-10-get-50-30-free-spins?wm=5369107&tdpeh=wqn7b2taQA',
   rel: 'noreferrer',
 },
 {
   id: 7,
   image: logo_7,
   title: '',
   to: 'https://www.top10bestonlinecasinos.co.uk/v?product_id=1112&url=https%3A%2F%2Fmediaserver.entainpartners.com%2FrenderBanner.do%3FzoneId%3D2129077%26tdpeh%3D%5Btracking-subid%5D&cms_platform=xsite&rank=11&bi=%7B%22blrs%22%3A82947%2C%22utm_source%22%3A%22Google%22%7D&comp_iid=dcfcd259-23df-4f25-8e8c-40836c800f03&uid=fAG1nPYA19VEXg623zbg&riid=EUfF8WVk933uqc0368dv&tkn=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiY21zIiwidmVyc2lvbiI6MSwiaGFzaCI6IkxLTzdhMXRQb29aS2JEUCtvdVZEbHJGVFhVcz0iLCJpYXQiOjE3MDc3MjA3MDYsImV4cCI6MTcwNzgwNzEwNn0.cHhuX7o7TrNroTCA6AKlvtwo_dqYxjTp74b5Bw7qipk&gid=45572406.1706266049',
   rel: 'noreferrer',
 },
 {
   id: 9,
   image: logo_9,
   title: '',
   to: 'https://www.top10bestonlinecasinos.co.uk/v?product_id=3324&url=https%3A%2F%2Fivyaffsolutions.com%2FC.ashx%3Fbtag%3Da_5885b_3c_%26affid%3D1240%26siteid%3D5885%26adid%3D3%26c%3D%5Btracking-subid%5D&cms_platform=xsite&rank=13&bi=%7B%22blrs%22%3A82758%2C%22utm_source%22%3A%22Google%22%7D&comp_iid=dcfcd259-23df-4f25-8e8c-40836c800f03&uid=fAG1nPYA19VEXg623zbg&riid=EUfF8WVk933uqc0368dv&tkn=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiY21zIiwidmVyc2lvbiI6MSwiaGFzaCI6IlhqRUpRM2YrUG1GN1VxTGlDY1l4VkE5QzQvWT0iLCJpYXQiOjE3MDc3MjA3MDYsImV4cCI6MTcwNzgwNzEwNn0.P9Q0_BEt2Q-rpY1JkR1SfMgYRJJg3mlKH83e9qjVaAU&gid=45572406.1706266049',
   rel: 'noreferrer',
 },
 {
   id: 3,
   image: logo_3,
   title: '',
   to: 'https://www.top10bestonlinecasinos.co.uk/v?product_id=374&url=https%3A%2F%2Fb1.trickyrock.com%2Fredirect.aspx%3Fpid%3D2839406%26bid%3D39678%26sref%3Dsource%26source%3D%5Btracking-subid%5D&cms_platform=xsite&rank=7&bi=%7B%22blrs%22%3A81811%2C%22utm_source%22%3A%22Google%22%7D&comp_iid=a0d5dea2-f48b-4026-86e7-7f6a919fbf5d&uid=fAG1nPYA19VEXg623zbg&riid=YltWsBPlDlYS899348dv&tkn=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiY21zIiwidmVyc2lvbiI6MSwiaGFzaCI6IjZIL1NWc3VmYWRFa09QLzVzR2Z3K3BQMXNPbz0iLCJpYXQiOjE3MDc3MTM3ODUsImV4cCI6MTcwNzgwMDE4NX0.FZEJZIHZowycdt2gAojtuU-ucWmjVXnwhHe9U6VAlbI&gid=45572406.1706266049',
   rel: 'noreferrer',
 },
 {
   id: 4,
   image: logo_4,
   title: '',
   to: 'https://www.top10bestonlinecasinos.co.uk/v?product_id=374&url=https%3A%2F%2Fb1.trickyrock.com%2Fredirect.aspx%3Fpid%3D2839406%26bid%3D39678%26sref%3Dsource%26source%3D%5Btracking-subid%5D&cms_platform=xsite&rank=7&bi=%7B%22blrs%22%3A81811%2C%22utm_source%22%3A%22Google%22%7D&comp_iid=a0d5dea2-f48b-4026-86e7-7f6a919fbf5d&uid=fAG1nPYA19VEXg623zbg&riid=YltWsBPlDlYS899348dv&tkn=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiY21zIiwidmVyc2lvbiI6MSwiaGFzaCI6IjZIL1NWc3VmYWRFa09QLzVzR2Z3K3BQMXNPbz0iLCJpYXQiOjE3MDc3MTM3ODUsImV4cCI6MTcwNzgwMDE4NX0.FZEJZIHZowycdt2gAojtuU-ucWmjVXnwhHe9U6VAlbI&gid=45572406.1706266049',
   rel: 'noreferrer',
 },

 {
   id: 6,
   image: logo_6,
   title: '',
   to: 'https://www.top10bestonlinecasinos.co.uk/v?product_id=881&url=https%3A%2F%2Fwlgamesysaffiliates.adsrv.eacdn.com%2FC.ashx%3Fbtag%3Da_46893b_13151c_%26affid%3D17686%26siteid%3D46893%26adid%3D13151%26c%3D%5Btracking-subid%5D&cms_platform=xsite&rank=10&bi=%7B%22blrs%22%3A81150%2C%22utm_source%22%3A%22Google%22%7D&comp_iid=dcfcd259-23df-4f25-8e8c-40836c800f03&uid=fAG1nPYA19VEXg623zbg&riid=EUfF8WVk933uqc0368dv&tkn=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiY21zIiwidmVyc2lvbiI6MSwiaGFzaCI6IkkvNDB4cHlhelBST0FnNjkrb3NlVXFlRG9Icz0iLCJpYXQiOjE3MDc3MjA3MDYsImV4cCI6MTcwNzgwNzEwNn0.OeAKByI_9fW1DmKdqjUrzB9K4NNiilsa5QpuI6MSNQg&gid=45572406.1706266049',
   rel: 'noreferrer',
 },

 {
   id: 8,
   image: logo_8,
   title: '',
   to: 'https://content.grosvenorcasinos.com/welcome-offer/100fs-30bonus/?SUB_ID=NR_F6F7EBA0546645209A1501579BD8B&sref=SUBID&SUBID=GkFuDYbZtt&AFF_ID=10008005823&pid=2776786',
   rel: 'noreferrer',
 },

 {
   id: 10,
   image: logo_10,
   title: '',
   to: 'https://www.betmgm.co.uk/campaigns/casino/welcome?btag=100667237_EB3F3F54834847EF8F5E3E9F1C249728&subid=uKyeicJBJi&pid=3767105&bid=19658',
   rel: 'noreferrer',
 },

 {
   id: 12,
   image: logo_11,
   title: '',
   to: 'https://casino.bet365.com/home/en?affiliate=365_02863180',
   rel: 'noreferrer',
 },
]

export {cards, links}