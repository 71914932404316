import React from 'react'

export default function Contact() {
  return (
    <div className='h-full'>
      <div className='bg-[#2C3639] h-[200px] w-full flex justify-center'>

        <h1 className='text-center text-2xl sm:text-5xl text-white  font-semibold  w-[70%] my-auto'> Wenn Sie mehr wissen möchten, kontaktieren Sie einfach</h1>
      </div>

      <div className='max-w-screen-md mx-auto p-5'>
        <div className='text-center mb-16'>
          <h3 className='text-3xl sm:text-4xl leading-normal font-extrabold tracking-tight text-white'>
          Eine Nachricht senden
          </h3>
        </div>

        <form className='w-full'>
          <div className='flex flex-wrap -mx-3 mb-6'>
            <div className='w-full px-3 mb-6 md:mb-0'>
              <label
                className='block uppercase tracking-wide text-white text-xs font-bold mb-2'
                htmlFor='grid-first-name'
              >
              Vorname
              </label>
              <input
                className='appearance-none block w-full bg-gray-200 text-gray-700 border  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white'
                id='grid-first-name'
                type='text'
                placeholder='Jane'
              />
            </div>
          </div>
          <div className='flex flex-wrap -mx-3 mb-6'>
            <div className='w-full px-3'>
              <label
                className='block uppercase tracking-wide text-white text-xs font-bold mb-2'
                htmlFor='grid-email'
              >
             E-Mail Adresse
              </label>
              <input
                className='appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
                id='grid-email'
                type='email'
                placeholder='********@*****.**'
              />
            </div>
          </div>

          <div className='flex flex-wrap -mx-3 mb-6'>
            <div className='w-full px-3'>
              <label
                className='block uppercase tracking-wide text-white text-xs font-bold mb-2'
                htmlFor='grid-password'
              >
              Ihre Nachricht
              </label>
              <textarea
                rows='10'
                className='appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
              ></textarea>
            </div>
            <div className='flex justify-between w-full px-3'>
              <div className='md:flex md:items-center'>
                <label className='block text-white font-bold'>
                  <input className='mr-2 leading-tight' type='checkbox' />
                  <span className='text-sm text-white'>Schicken Sie mir Ihren Newsletter!</span>
                </label>
              </div>
              <button
                className='shadow bg-[#CC9900] hover:bg-[#cc9900bc] focus:shadow-outline focus:outline-none text-white font-bold py-2 px-6 rounded'
                type='submit'
              >
              Nachricht senden
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}
