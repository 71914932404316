import logo from "../assets/home/logo.webp";
import Container from "./Container";

export default function Footer() {
  return (
    <footer className="bg-[#2C3639] py-14 text-white border-t-[1px] border-slate-100 ">
      <Container>
        <div className="flex place-items-center sm:text-left text-center justify-between ">
          <img src={logo} alt="Grant canyon" className="w-[100px] h-[100px]" />
          <div className="sm:mt-0 mt-14">
            <h1 className="font-bold capitalize sm:pt-0 pt-8 pb-4 text-sm mx-5">
              Spielen Sie verantwortungsbewusst. Zutritt nur für Personen über
              18 Jahre. Unsere Spiele sind für ein erwachsenes Publikum gedacht.
               Wenn Sie das Gefühl haben, dass
              Sie Hilfe benötigen, zögern Sie nicht, professionelle
              Unterstützung zu suchen. Urheberrechte und Schutz des geistigen
              Eigentums Alle Inhalte, die auf unserer Webseite bereitgestellt
              werden, einschließlich Texte, Grafiken, Logos, Bilder und
              Software, sind Eigentum von freeluckygame.com oder werden mit
              Erlaubnis verwendet. Diese Materialien sind durch
              Urheberrechtsgesetze geschützt und dürfen nicht ohne vorherige
              schriftliche Genehmigung von freeluckygame.com kopiert,
              reproduziert, vertrieben oder anderweitig verwendet werden. © 2024
              freeluckygame.com. Alle Rechte vorbehalten. Durch die Nutzung
              unserer Website erklären Sie sich mit unseren Nutzungsbedingungen
              und Datenschutzrichtlinien einverstanden. Unsere
              Nutzungsbedingungen und Datenschutzrichtlinien bieten detaillierte
              Informationen darüber, wie wir Ihre Daten schützen und wie Sie
              Ihre Rechte ausüben können. Wir verpflichten uns zum Schutz Ihrer
              Privatsphäre und zur Einhaltung aller relevanten Gesetze und
              Vorschriften.
            </h1>
          </div>
        </div>
      </Container>
    </footer>
  );
}
