import card from '../assets/home/card.webp'
import { Link } from 'react-router-dom'
export default function About() {
  return (
    <>
      <div className=' bg-opacity-30 sm:px-8 py-14 rounded-md mt-8 w-[90%] mx-auto'>
        <div className='grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 place-items-center lg:gap-14 gap-8'>
          <div className='text-center flex flex-col items-center justify-center text-white'>
            <h2>Über uns</h2>
            <h1 className='font-bold text-lg py-4'>Erfahren Sie mehr über uns</h1>
            <p>
            Willkommen in der  freeluckygame.com , der Heimat des Universums der Unterhaltung
                Der Nervenkitzel übertrifft das Glücksspiel. Wir sind Ihr kosmisches Tor zu einem
                Eine Welt aufregender kostenloser Social-Casino-Erlebnisse mit
                Eine großartige Spielereihe, zu der auch ein kostenloses Social-Casino gehört
                Stud Poker, Double Exposure Blackjack und das rätselhafte Geheimnis
                Joker 6000. Wir nehmen Sie mit auf eine Reise durch den Kosmos
                ausgezeichnetes Gaming. Bei  freeluckygame.com  ist es unsere Mission
                Bereitstellung eines unübertroffenen Spielerlebnisses, das Innovation,
                Spannung und Transparenz. Wir sind bestrebt, eine Umgebung zu schaffen
                Hier können alle Spieler ihr Lieblings-Social kostenlos genießen
                Spielen Sie mit Zuversicht Casinospiele und entdecken Sie neue Interessen
                Unterhaltung.
            </p>
            <Link to="/Games" target='_blank' rel="noreferrer">
            <button className='capitalize mt-6 bg-[#CC9900] hover:bg-[#cc9900bc] transition-colors px-14 py-3 rounded-sm font-bold text-white'>
            In Verbindung bleiben
            </button>
            </Link>
          </div>
          <div>
            <img src={card} alt='card' />
          </div>
        </div>
      </div>
      <div className='w-[90%] mx-auto text-white'>
        <h2 className=' text-3xl font-extrabold my-4 text-center text-white '>
        Willkommen auf der besten Plattform für kostenlose Casino-Spiele,
            Wo die Aufregung nie aufhört!
        </h2>
        <p className='text-white text-base font-medium my-6'>
        Willkommen bei  freeluckygame.com , wo sich die Welten der Unterhaltung treffen
            der Nervenkitzel des Glücksspiels. Wir sind Ihr kosmisches Tor zu einer Welt davon
            Aufregende kostenlose Social-Casino-Erlebnisse, mit Sternen
            Spiele, einschließlich des kostenlosen Social-Casinos Stud Poker,
            Double Exposure Blackjack und der rätselhafte Mystery Joker 6000. Erlauben
            Wir nehmen Sie mit auf eine Reise durch den Kosmos der Gaming-Exzellenz.
            Unsere Mission bei  freeluckygame.com  ist es, unübertroffenes Gaming anzubieten
            ein Erlebnis, das Innovation, Spannung und Transparenz vereint. Wir
            Versuchen Sie, eine Umgebung zu schaffen, in der alle Spieler Spaß haben können
            Die beliebtesten kostenlosen Social-Casinospiele, denen Sie vertrauen und die Sie entdecken können
            ein neues Feld der Unterhaltung.
        </p>
        <h3 className='font-bold mx-10'>Dinge, die uns auseinander reißen</h3>
        <ul className='mx-10 '>
          <li className="my-4">
          1. Auswahl an Spielen: Egal, ob Sie Poker oder Blackjack mögen
              Profi oder Fans spannender Spielautomaten ist  freeluckygame.com 
              etwas für jeden. Dafür sorgt unsere vielfältige Spieleauswahl
              Es gibt nie einen dunklen Moment.
          </li>
          <li className="my-4">
          2. Spitzentechnologie: Wir nutzen die neueste Technologie, die wir anbieten können
              eine nahtlose, sichere und benutzerfreundliche Gaming-Plattform. Entdecken Sie Ihr
              Lieblingsspiele zu Ihren Bedingungen, mit dem Komfort sowohl im Internet als auch
              mobiles Spielen.
          </li>
          <li className="my-4">
          3. Fair Play:  freeluckygame.com  setzt sich für gleiche Wettbewerbsbedingungen ein. Wir sorgen dafür
              dass alle Spiele fair und transparent sind und ein Level bieten
              Spielfeld für alle unsere Spieler.
          </li>
          <li className="my-4">
          4. Kundenorientierter Ansatz: Ihre Zufriedenheit ist unsere Priorität. Unser
              Ein engagiertes Support-Team steht Ihnen rund um die Uhr zur Verfügung, um Ihnen bei allen Fragen zu helfen
              Anfragen oder Bedenken, um sicherzustellen, dass Ihr Spielerlebnis so ist
              möglichst bereit.
          </li>
        </ul>
        <h3 className='font-bold mx-10'>Unsere Spiele</h3>
        <ul className='mx-10 list-disc'>
          <li className="my-4">
          Poker Stud Casino: Genießen Sie die Spannung des Pokers auf einer kostenlosen Social-Casino-Website, wo Sie Ihre Fähigkeiten gegen den Dealer testen und versuchen können, große Gewinne zu erzielen.
          </li>
          <li className="my-4">
          Double Exposure Blackjack: Bei dieser einzigartigen Version des Blackjack werden beide Dealerkarten offengelegt, sodass Sie mehr Informationen erhalten, um strategische Entscheidungen zu treffen.
          </li>
          <li className="my-4">
          Mystery Joker 6000: Begeben Sie sich auf eine erstaunliche Reise mit dem rätselhaften Mystery Joker 6000-Slot, bei dem an jeder kosmischen Ecke Überraschungen und Boni auf Sie warten.
          </li>
          <li className="my-4">
          Treten Sie uns noch heute bei  freeluckygame.com  lädt Sie ein, unserer Community aus Weltraumforschern und Gaming-Enthusiasten beizutreten. Egal, ob Sie ein erfahrener Spieler oder Neuling in der Welt der kostenlosen Social-Casinospiele sind, wir bieten für jeden eine aufregende, sichere und unterhaltsame Umgebung. Machen Sie sich bereit für den Eintritt in ein Universum voller unendlicher Möglichkeiten und astronomischer Talente.
          </li>
        </ul>
      </div>
    </>
  )
}
