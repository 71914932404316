import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="max-w-3xl mx-auto p-4 text-white">
      <h1 className="text-3xl font-bold mb-4">Datenschutzerklärung</h1>
      <p className="mb-4">Herzlich willkommen bei der Datenschutzerklärung von freeluckygame.com ("Website"), abrufbar unter https://freeluckygame.com. Wir legen Wert auf die Privatsphäre und Sicherheit unserer Besucher und diese Richtlinie beschreibt, wie wir Ihre Informationen sammeln, verwenden, schützen und verwalten.</p>
      <p className="mb-4">Wenn Sie Fragen haben oder Erläuterungen zu unserer Datenschutzerklärung benötigen, können Sie sich gerne an uns wenden.</p>

      <h2 className="text-xl font-semibold mb-2">Logdateien</h2>
      <p className="mb-4">Wir nutzen ernsthafte Protokolle zur Verwaltung von Logdateien auf der Website freeluckygame.com. Diese Dateien dokumentieren die Interaktion der Besucher mit unserer Website. Solche Verfahren sind branchenüblich und stellen einen wesentlichen Bestandteil der analytischen Infrastruktur von Hosting-Diensten dar. Die von diesen Journaldateien protokollierten Informationen umfassen Internetprotokoll (IP)-Adressen, Browserspezifikationen, Details zum Internetdienstanbieter (ISP), Datum- und Zeitstempel, die Anmeldezeiten anzeigen, Verweis- und Ausgangsseiten sowie potenziell die Geschwindigkeit von Klicks. Es ist wichtig zu beachten, dass keine dieser Informationen direkt mit personenbezogenen Daten verknüpft ist. Die Hauptzwecke der Erhebung dieser Informationen sind die Identifizierung vorherrschender Trends, die erleichterte Verwaltung der Website, das Nachverfolgen von Navigationsmustern der Benutzer und das Zusammenstellen von demografischen Erkenntnissen.</p>

      <h2 className="text-xl font-semibold mb-2">Datenschutzerklärung von Drittanbietern</h2>
      <p className="mb-4">Bitte beachten Sie die bereitgestellte Liste, um auf die Datenschutzerklärung für jeden unserer Werbepartner zuzugreifen, die auf der Website freeluckygame.com angezeigt wird.</p>
      <p className="mb-4">Drittanbieter-Werbeserver oder -Werbennetzwerke nutzen komplexe Technologien wie Cookies, JavaScript oder Web Beacons innerhalb der entsprechenden Anzeigen und Hyperlinks, die auf der Website freeluckygame.com angezeigt werden. Diese Mechanismen werden direkt an die Browser der Benutzer übertragen und erhalten dadurch automatisch Ihre IP-Adresse. Diese Mechanismen dienen verschiedenen Zwecken, einschließlich der Bewertung der Effektivität von Werbekampagnen und der Anpassung von Werbeinhalten basierend auf dem Browserverhalten der Benutzer.</p>

      <h2 className="text-xl font-semibold mb-2">Nur Online-Datenschutzrichtlinie</h2>
      <p className="mb-4">Unsere Datenschutzrichtlinie bezieht sich ausschließlich auf Online-Aktivitäten und gilt nur für Besucher, die unsere Website freeluckygame.com besuchen und dabei Informationen offenlegen und/oder gesammelt werden. Diese Richtlinie umfasst keine Informationen, die über Offline-Kanäle oder alternative Umgebungen außerhalb des Umfangs dieser Website gesammelt wurden.</p>

      <h2 className="text-xl font-semibold mb-2">Zustimmung</h2>
      <p className="mb-4">Durch die Nutzung der Dienste unserer Website erklären Sie sich ausdrücklich mit unserer Datenschutzrichtlinie einverstanden und bestätigen, dass Sie sich damit einverstanden erklären, durch die entsprechenden Nutzungsbedingungen gebunden zu sein.</p>

      <p className="mb-0">© Alle Rechte vorbehalten.</p>
    </div>
  );
}

export default PrivacyPolicy;
