import Container from '../Container'

export default function Quiz() {
  return (
    <section className='my-14'>
      <Container>
        <div className='h-auto bg-amber-600 rounded-xl'>
          <article className='py-8 sm:py-24 md:px-14 px-4 md:w-9/12 md:mx-0 mx-auto md:text-left text-center leading-none'>
            <h1 className='font-bold lg:text-[60px]  sm:text-[30px] text-white pb-8'>
             Über uns
            </h1>
            <p className='font-normal lg:text-[20px] text-[14px] text-white pb-8'>
              Diese Plattform wurde vollständig kostenlos erstellt und dient
              ausschließlich Unterhaltungszwecken. Alle Aktivitäten auf dieser
              kostenlosen Plattform zielen darauf ab, die Freizeit der Nutzer zu
              bereichern und für Vergnügen zu sorgen. Es ist wichtig zu
              beachten, dass diese kostenlose Website für erwachsene Benutzer
              (18+) bestimmt ist. Bitte verwenden Sie die Plattform
              verantwortungsbewusst und respektvoll. Die Inhalte, die auf dieser
              Plattform geteilt werden, sind rein auf Unterhaltung ausgerichtet
              und verfolgen keinerlei kommerzielle Absichten. Die Website bietet
              Nutzern die Freiheit, frei zu stöbern und Inhalte zu entdecken,
              jedoch ist es wichtig, dass dies auf respektvolle Weise geschieht.
              Wir möchten eine Gemeinschaft schaffen, in der sich alle Nutzer
              wohl fühlen. Bitte beachten Sie die Gemeinschaftsregeln und tragen
              Sie dazu bei, eine sichere Umgebung für alle zu gewährleisten.
              Diese kostenlose Website richtet sich an Nutzer ab 18 Jahren und
              bietet eine breite Palette von Inhalten für Ihr Vergnügen. Viel
              Spaß beim Erkunden!
            </p>
          </article>
        </div>
      </Container>
    </section>
  )
}
