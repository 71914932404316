import { Link } from 'react-router-dom';

export default function Front({ user }) {
  return (
    <section className='mx-auto w-11/12'>
      <div className='py-8 sm:rounded-3xl relative w-full h-[200px] sm:h-[620px] bg-cover lg:bg-center bg-no-repeat bg-homeBackground'>
        <div className='flex items-end absolute bottom-0 sm:bottom-10 left-0 right-0 px-2 sm:px-6 py-4 space-x-2 sm:space-x-4'>
          {/* {user === 'user' ? (
            <a
              href='https://www.top10bestonlinecasinos.co.uk/'
              target='_blank'
              rel='noopener noreferrer'
              className='button-style capitalize bg-amber-500 hover:bg-amber-400'
            >
              Spiele
            </a>
          ) : (
            <Link to='/Games/1' className='button-style capitalize bg-amber-500 hover:bg-amber-400' >
              Spiele spielen
            </Link>
          )} */}

          <Link to='/About' className='button-style capitalize bg-amber-600 hover:bg-amber-400'>
            Mehr wissen
          </Link>
        </div>

        <div className='lg:flex hidden items-end absolute top-[30%] right-0 w-[30%] border-l-2 h-1/2 px-2 border-l-white'>
          <p className='text-white text-[36px] w-[80%] font-semibold'>
          freeluckygame.com  Sie zu den Sternen führt!
          </p>
        </div>
      </div>
    </section>
  );
}

