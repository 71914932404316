import {  Route, Routes } from "react-router-dom";
import React from "react";
import Home from "./Pages/Home";
import About from "./Pages/About";
import Contact from "./Pages/Contact";
import Games from './Pages/Games';
import Privacy from './Pages/Privacy'
import Terms from './Pages/Terms'

export default function MyRoutes({ user }) {
  return (
    <Routes>
      <Route path="/" element={<Home user={user}/>} />
      <Route path="/About" element={<About />} />
      <Route path="/Contact" element={<Contact />} />
      <Route path="/games/:name" element={<Games />} />
      <Route path="/privacy" element={<Privacy />} />
      <Route path="/terms-and-conditions" element={<Terms />} />
    </Routes>
  );
}
